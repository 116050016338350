<template>
    <section class="mt-4">
      <b-tabs>
        <b-tab-item label="Таблица">
          <b-pagination
            :total="total"
            :current="currentPage"
            :simple="false"
            :per-page="filters.limit"
            :paginated="false"
            :pagination-simple="true"
            order="is-right"
            @change="pageChange">
          </b-pagination>
          <div class="box">
            <b-field grouped group-multiline>
              <b-field label="Cостояние">
                <b-select v-model="filters.is_deleted" @input="pageChange()">
                  <option :value="true">Удаленые</option>
                  <option :value="false">Активные</option>
                </b-select>
              </b-field>
            </b-field>
          </div>
          <div class="table-container">
            <b-table
              :data="data"
              :bordered="true"
              :narrowed="true"
              :hoverable="true"
              :focusable="true"
              default-sort-direction="desc"
              default-sort="date">

              <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
                <router-link :to="{name: 'a-group-dishes', params: {id: props.row.id}}">
                  {{ props.row.id }}
                </router-link>
              </b-table-column>

              <b-table-column field="name" label="Наименование" centered v-slot="props">
                {{ props.row.name }}
              </b-table-column>

              <b-table-column field="count_dishes" label="Количество блюд" centered v-slot="props">
                {{ props.row.count_dishes }}
              </b-table-column>

              <b-table-column field="up" label="Поднять" centered v-slot="props">
              <template v-if="props.row.sort_id > 1">
                <button class="button is-link is-light" v-on:click="UP(props.row.sort_id)">
                  <font-awesome-icon icon="arrow-up" />
                </button>
              </template>
              <template v-else="">
                <strong>
                  -
                </strong>
              </template>
            </b-table-column>

            <b-table-column field="down" label="Опустить" centered v-slot="props">
              <button class="button is-link is-light" v-on:click="DOWN(props.row.sort_id)">
                <font-awesome-icon icon="arrow-down" />
              </button>
            </b-table-column>

              <b-table-column field="action" label="Действие" centered v-slot="props">
                <b-tooltip label="Просмотр">
                  <router-link :to="{name: 'a-group-dishes', params: {id: props.row.id }}" class="navbar-item">
                    <font-awesome-icon icon="eye" />
                  </router-link>
                </b-tooltip>
              </b-table-column>
            </b-table>
        </div>
      </b-tab-item>
      <b-tab-item label="JSON">
        <div class="app-json">
          <vue-json-pretty
            :path="'res'"
            :data="data">
          </vue-json-pretty>
        </div>
      </b-tab-item>
    </b-tabs>
    </section>
</template>


<script>
import { EventBus, limits } from '@/utils'
import {
  getGroups,
  getPageGroups,
  upGroup,
  downGroup
} from '@/api/group'
// import { ToastProgrammatic as Toast } from 'buefy'

import {
  state_yesno
} from '@/utils'

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: "GroupTable",
  components: {
    VueJsonPretty
  },
  data() {
    const data = []

    return {
      state_yesno: state_yesno,

      data: data,
      limits: limits,
      currentPage: 1,
      total: 0,
      filters: {
        order: 'asc',
        limit: 25,
        is_deleted: false
      },
      is_admin: false
    }
  },
  computed: {
    //
  },
  mounted: function() {
    let $this = this

    getGroups(this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;
        $this.total = data.total

        data.data.forEach((item) => {
          this.data.push(item)
        })
      })
      .catch(
        this.data = []
      )

    // EventBus.$on('error', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.error')} ${error}`,
    //     message: 'error',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

    // EventBus.$on('fail', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.fail')} ${error}`,
    //     message: 'fail',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

  },
  beforeDestroy: function(){
    EventBus.$off('error')
    EventBus.$off('fail')
  },
  methods: {
    pageChange(page){
      if(page){
        this.currentPage = page
      }
      getPageGroups(this.currentPage, this.filters, this.$store.state.jwt_user)
        .then(response => {
          let data = response.data;
          
          this.data = []
          this.total = data.total
          data.data.forEach((item) => {
            this.data.push(item)
          })

        })
        .catch(
          this.data = []
        )
    },


      UP(id){
        upGroup(id, this.$store.state.jwt_user).then(() =>
          { 
              getPageGroups(this.currentPage, this.filters, this.$store.state.jwt_user)
                .then(response => {
                  let data = response.data
                  
                  this.data = []

                  this.total = data.total

                  data.data.forEach((item) => {
                    this.data.push(item)
                  })

                })
                .catch(
                  this.data = []
                )

          }
        )
      },

      DOWN(id){
        downGroup(id, this.$store.state.jwt_user).then(() =>
          { 
              getPageGroups(this.currentPage, this.filters, this.$store.state.jwt_user)
                .then(response => {
                  let data = response.data
                  
                  this.data = []

                  this.total = data.total

                  data.data.forEach((item) => {
                    this.data.push(item)
                  })

                })
                .catch(
                  this.data = []
                )
          }
        )
      }
  }
}
</script>
<style>
</style>